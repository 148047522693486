<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.course')}`"
  >
    <course-form
      :course="course"
      @submit="handleSubmit"
      :invalid="invalid"
      :invalidNo="invalidNo"
      :invalidEn="invalidEn"
      :invalidBuilding="invalidBuilding"
      :invalidType="invalidCourseType"
      @cancel="handleCancel"
    ></course-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import CourseForm from '../components/CourseForm';
import { createCourse } from '../api';

export default {
  data() {
    return {
      course: {
        title: [
          { lang: 'no', value: '' },
          { lang: 'en', value: '' }
        ],
        description: [
          { lang: 'no', value: '' },
          { lang: 'en', value: '' }
        ],
        courseType: { id: 1, name: 'standard' },
        building: null,
        validLength: null
      },
      loading: false
    };
  },

  components: {
    UiCard,
    CourseForm
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      if (this.course.courseType.name === 'student') {
        this.course.hasExam = true;
      }
      try {
        const course = await createCourse(this.course);
        this.$router.push({ name: 'courses-show', params: { id: course.id } });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        let addon = '';
        if (
          this.course.courseType &&
          this.course.courseType.name == 'student'
        ) {
          addon = '\nDet kan kun være et studentkurs pr bygg.';
        }
        this.$message({
          message: `${this.$t('errors.general')}${addon}`,
          type: 'error'
        });
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  computed: {
    invalidNoTitle() {
      return this.course.title[0].value !== '' ? 0 : 1;
    },

    invalidNoDescr() {
      return this.course.description[0].value !== '' &&
        this.course.description[0].value !== '<p></p>'
        ? 0
        : 1;
    },

    invalidNo() {
      return this.invalidNoTitle + this.invalidNoDescr;
    },

    invalidEnTitle() {
      return this.course.title[1].value !== '' ? 0 : 1;
    },

    invalidEnDescr() {
      return this.course.description[1].value !== '' &&
        this.course.description[1].value !== '<p></p>'
        ? 0
        : 1;
    },

    invalidEn() {
      return this.invalidEnTitle + this.invalidEnDescr;
    },

    invalidBuilding() {
      return this.course.building && this.course.building.id ? 0 : 1;
    },

    invalidCourseType() {
      return this.course.courseType && this.course.courseType.id ? 0 : 1;
    },

    invalid() {
      return (
        this.invalidNo +
        this.invalidEn +
        this.invalidBuilding +
        this.invalidCourseType
      );
    }
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.a_course", link: "/app/courses/"},
      {name: "actions.create", link: ""}
    ]);
  },
};
</script>
<style scoped>
.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>
